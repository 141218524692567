import {Branding} from '../Branding.types'

import LogoHeidelbergMaterials from './icons/Logo-HM-Compact-Negative.svg'

export const hubHeidelbergMaterialsCoUk: Branding = {
  nationalBrandName: 'Heidelberg Materials',
  nationalLogoUrl: LogoHeidelbergMaterials,
  nationalUrl: 'https://www.hub.heidelbergmaterials.co.uk',
  nationalLogoTitle: 'Heidelberg Materials',

  contactEmail: 'hconnect@uk.heidelbergmaterials.com',
  contactEmailSubject: 'Hub - Support Request - Hanson',

  pageTitle: 'Heidelberg Materials',
  country: 'GB',
  termsAndConditionsOfSale: 'https://www.heidelbergmaterials.co.uk/en/terms-and-conditions-of-sale',
  dispatchPhoneNumber: '+4403301234520'
}
