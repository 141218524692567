import {Branding} from '../Branding.types'

import LogoHM from './icons/Logo-HM-Compact-Negative.svg'

export const hubHeidelbergMaterialsDe: Branding = {
  nationalBrandName: 'Heidelberg Materials',
  nationalLogoUrl: LogoHM,
  nationalLogoTitle: 'Heidelberg Materials',
  nationalUrl: 'https://www.heidelbergmaterials.de/',
  contactEmail: 'hub.zement@heidelbergmaterials.com',
  contactFormHref: 'https://forms.office.com/e/kTm9yzf1pL',
  contactEmailSubject: 'Kundenanliegen zum Hub Portal',
  pageTitle: 'Heidelberg Materials',
  country: 'DE'
}
