import {Branding} from '../Branding.types'

import LogoHM from './icons/Logo-HM-Compact-Negative.svg'

export const hubHeidelbergMaterialsBenelux: Branding = {
  nationalBrandName: 'Heidelberg Materials',
  nationalLogoUrl: LogoHM,
  nationalUrl: 'hub.heidelbergmaterials-benelux.com',
  nationalLogoTitle: 'Heidelberg Materials',
  contactEmail: 'InfoBENE@heidelbergmaterials.com',
  contactEmailSubject: 'Notez la raison de votre demande / Noteer de reden van uw vraag',
  pageTitle: 'Heidelberg Materials',
  country: 'BE'
}
