import {Branding} from '../Branding.types'

import LogoHM from './icons/Logo-HM-Compact-Negative.svg'

export const hubHeidelbergMaterialsBg: Branding = {
  nationalBrandName: 'Heidelberg Materials Devnya JSC; Heidelberg Materials Vulkan JSC',
  nationalLogoUrl: LogoHM,
  nationalUrl: 'https://www.heidelbergmaterials.bg',
  nationalLogoTitle: 'Heidelberg Materials Devnya JSC; Heidelberg Materials Vulkan JSC',
  contactEmail: 'Hub-BG@heidelbergmaterials.com',
  contactEmailSubject: 'Mail from Hub Portal',
  pageTitle: 'Hub - Heidelberg Materials Bulgaria',
  termsAndConditionsOfSale: 'https://www.heidelbergmaterials.bg',
  country: 'BG'
}
