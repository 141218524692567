import {Branding} from '../Branding.types'

import LogoHM from './icons/Logo-HM-Compact-Negative.svg'

export const hubHeidelbergMaterialsNo: Branding = {
  nationalBrandName: 'Heidelberg Materials',
  nationalLogoUrl: LogoHM,
  nationalLogoTitle: 'Heidelberg Materials',
  nationalUrl: 'https://www.heidelbergmaterials.no/',
  contactEmail: 'hub-no@heidelbergmaterials.com',
  contactEmailSubject: 'Mail from Hub Portal',
  pageTitle: 'Heidelberg Materials',
  country: 'NO'
}
